:root {
  --colorPrimario: #124734;
  --colorSecundario: #C2D500;
  --colorTerciario: #2A7050;
  --colorGris: #3B4A49;
  --colorFondoMain: #efefef;
  --colorTextos: #242424;
  --colorFilaTabla: #dbdbdb;
  --fuentePrimaria: 'Provicali', sans-serif;
  --fuenteSecundaria: 'Roboto', sans-serif;
  --paddinLateral: 0px 150px;
  --paddinLateralMobile: 0px 10px;
  --alturaNavBar: 100px;
  --alturaNavBarMobile: 80px;
  --bs-secondary-bg: rgb(253, 253, 253) !important;
}
* {
  margin: 0px;
}
p, label, span, tr {
  color: var(--colorTextos);
  font-family: var(--fuenteSecundaria);
  font-weight: 300;
}
h1,h2{
  color: var(--colorTerciario);
  font-family: var(--fuentePrimaria);
  text-transform: uppercase;
  font-size: 2rem;
  @media (min-width: 576px){
    font-size: 2.5rem;
  }
}
h3,h4,h5,h6{
  color: var(--colorTextos);
  font-family: var(--fuentePrimaria);
  text-transform: uppercase;
}
a{
  color: var(--colorTextos);
  font-family: var(--fuentePrimaria);
  text-decoration: none;
}
ul{
  list-style: none;
}
.containerBarton{
  display: flex;
  flex-wrap: wrap;
  padding: var(--paddinLateralMobile);
  @media (min-width: 768px) {
    padding: var(--paddinLateral);
  }
    
}
@media(min-width: 992px){
  .containerBarton{
    display: flex;
    flex-wrap: nowrap;
  }
}
@media(min-width: 768px){
  .containerBarton{
    display: flex;
    flex-wrap: wrap;
  }
}